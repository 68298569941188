import React from "react"
import { graphql } from 'gatsby'
import Img from "gatsby-image/withIEPolyfill"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Accordion from "../components/Accordion"

export default ({ data }) => {
  const page = data.strapiCenturionMobilecheckin
  let sources = []
  if (page.heroImage) {
    sources = [
      page.heroImage.localFile.childImageSharp.mobileImage,
      {
        ...page.heroImage.localFile.childImageSharp.desktopImage,
        media: `(min-width: 676px)`,
      },
    ]
  }
  return (
    <Layout>
      <SEO metaTitle={page.metaTitle} />
      <Header />
      <div className="digitalwaitlist">
        {page.heroImage &&
          <div className="hero">
            <Img fluid={sources} />
          </div>
        }
        <div className="container container--wide island--dubs">
          <div className="grid-wrapper align--center">
            <div className="grid lap-one-half">
              <h1 className="h2 title" dangerouslySetInnerHTML={{__html: page.title}} />
              <div dangerouslySetInnerHTML={{__html: page.content}} />
            </div>
            <div className="grid lap-one-half hidden-below-lap">
              <Img className="img-right" fluid={page.sideImage.localFile.childImageSharp.fluid} />
            </div>
          </div>
        </div>
        {page.accordion &&
          <div class="container">
            <Accordion accordion={page.accordion} />
          </div>
        }
        <div className="container container--med island">
          <p className="footnote text-center">{page.footnote}</p>
        </div>
        <div className="legal">
          <div className="container container--med island" dangerouslySetInnerHTML={{__html: page.legal}} />
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiCenturionMobilecheckin {
      metaTitle
      title
      heroImage {
        localFile {
          childImageSharp {
          desktopImage: fluid(maxWidth: 1200, maxHeight: 420) {
            ...GatsbyImageSharpFluid
          }
          mobileImage: fluid(maxWidth: 676, maxHeight: 540) {
            ...GatsbyImageSharpFluid
          }
        }
        }
      }
      content
      sideImage {
        localFile {
          childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
        }
      }
      accordion {
        title
        steps {
          number
          stepImage {
            localFile {
              childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
            }
          }
          description
        }
      }
      footnote
      legal
    }
  }
`
